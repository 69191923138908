<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1 add-company-document-page"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("resources", "edit-resource", "description") }}
        </div>
        <template v-slot:right> </template>
      </top-header-menu-wrapper>
    </template>
    <!-- Form start -->
    <VeeForm
      ref="addCompanyDocument"
      :key="formKey"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
      :initial-values="editCompanyDocument"
      style="height: 97.5%; margin-top: 3%;"
    >
      <ul v-if="!isPreview" class="clebex-item-section mail-flex">
        <li class="clebex-item-section-item mail-flex">
          <span class="error-message" v-if="errors.content">
            {{ errors.content }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <Field
                name="content"
                as="textarea"
                type="text"
                :placeholder="
                  displayLabelName('resources', 'edit-resource', 'description')
                "
                v-model="description"
              />
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section mail-flex" v-if="description && isPreview">
        <li class="clebex-item-section-item mail-flex">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input preview">
              <div v-html="description"></div>
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" ref="submitAddForm" style="display: none"></button>
    </VeeForm>
    <!-- Form end -->

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: isPreview }"
              @click="isPreview = !isPreview"
            >
              {{ displayLabelName("documents", "edit-document", "preview") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";

export default {
  name: "EditCompanyDocument",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      isPreview: false,
    };
  },
  computed: {
    ...mapState("resource", ["resource"]),
    description: {
      get() {
        if (
          this.resource &&
          this.resource.data &&
          this.resource.data.description
        ) {
          return this.resource.data.description;
        }
        return null;
      },
      set(val) {
        this.$store.commit("resource/setDescription", val, { root: true });
      },
    },
  },
  created() {
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true,
    });
  },
  methods: {},
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true,
    });
  },
};
</script>
